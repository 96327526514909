import { endOfToday, isAfter, isBefore, parseISO, startOfToday } from 'date-fns'
import dayjs from 'dayjs'

import { JsonDate } from '~typings/core'

/**
 * Checks whether the current date is before today
 * @param date The date to check
 * @returns True if the date is before today, false otherwise
 * @note this is not equivalent to `!isDateAfterToday(date)`, and will fail if the date is indeed today.
 * @example
 * ```ts
 * // today is 2020-01-01
 * isDateAfterToday('2020-01-01') // false
 * isDateBeforeToday('2020-01-01') // also false
 *
 * isDateAfterToday('2020-01-01') === !isDateBeforeToday('2020-01-01') // false
 * ```
 */
export const isDateBeforeToday = (date: number | Date) => {
  return isBefore(date, startOfToday())
}

/**
 * Checks whether the current date is after today
 * @param date The date to check
 * @returns True if the date is after today, false otherwise
 * @note this is not equivalent to `!isDateBeforeToday(date)`, and will fail if the date is indeed today.
 * @example
 * ```ts
 * // today is 2020-01-01
 * isDateAfterToday('2020-01-01') // false
 * isDateBeforeToday('2020-01-01') // also false
 *
 * isDateAfterToday('2020-01-01') === !isDateBeforeToday('2020-01-01') // false
 * ```
 */
export const isDateAfterToday = (date: number | Date) => {
  return isAfter(date, endOfToday())
}

// Converts UTC time to the same date in local time, ignoring original timezone.
export const fromUtcToLocalDate = (date?: Date | null) => {
  if (!date) return date
  return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
}

/**
 * Checks whether given date is out of (start, end] range, inclusive start only.
 * If no start or end is given, it will be treated an unbounded range in that direction.
 * @param date Date to check
 * @param start Start of range
 * @param end End of range
 * @returns Whether date is out of range
 */
export const isDateOutOfRange = (
  date: number | Date,
  start?: number | Date | null,
  end?: number | Date | null,
) => {
  if (start && end) {
    return isBefore(date, start) || isAfter(date, end)
  }
  if (start) {
    return isBefore(date, start)
  }
  if (end) {
    return isAfter(date, end)
  }

  return false
}

export const ISO_DATE_FORMAT_REGEX =
  /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?(Z|[+-](\d+(:\d+)?))?$/

export const isIsoDateString = (value: unknown): value is JsonDate => {
  return (
    typeof value === 'string' &&
    ISO_DATE_FORMAT_REGEX.test(value) &&
    !isNaN(new Date(value).getTime())
  )
}

export const SHORT_ISO_DATE_FORMAT_REGEX = /^\d{4}-\d{2}-\d{2}$/

export const isShortIsoDateString = (value: unknown): value is JsonDate => {
  return (
    typeof value === 'string' &&
    SHORT_ISO_DATE_FORMAT_REGEX.test(value) &&
    !isNaN(new Date(value).getTime())
  )
}

/**
 * Changes hour and minute of a date with the given date, hour, and minute
 * @param date Date in which its hour and minute will be changed
 * @param time the string with the format of 'hh:mm'
 * @returns the new Date with the given time
 */
export const changeTimeOfTheDate = (date: Date, time: string): Date => {
  const [hour, minute] = time.split(':')

  return dayjs(date)
    .hour(+hour)
    .minute(+minute)
    .toDate()
}

/**
 * This function mutates given @param body, and transforms all ISO date strings
 * in the body object to Date objects.
 * @param body object to transform
 */
export const mutableTransformAllIsoStringsToDate = (body: unknown) => {
  if (body === null || body === undefined || typeof body !== 'object') {
    return
  }

  for (const key of Object.keys(body)) {
    const value = (body as Record<string, unknown>)[key]
    if (isIsoDateString(value)) {
      // eslint-disable-next-line @typescript-eslint/no-extra-semi
      ;(body as Record<string, unknown>)[key] = parseISO(value)
    } else if (typeof value === 'object') {
      mutableTransformAllIsoStringsToDate(value)
    }
  }
}

/**
 * Helper method that calls `mutableTransformAllIsoStringsToDate` internally to
 * return the mutated @param body.
 * @param body object to transform
 * @returns mutated object with all ISO date strings transformed to Date objects.
 */
export const transformAllIsoStringsToDate = <T>(body: T): T => {
  mutableTransformAllIsoStringsToDate(body)
  return body
}
